import React, { useEffect, useRef } from 'react';
import Slider from 'react-slick';
import { Box } from '@mui/material';
import useResponsive from '@/components/hooks/useResponsive';
import { SxClassProps } from '@/types';
import TestimonialSlide from './TestimonialSlide';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

// Types & Interfaces
type TSliderProps = React.ComponentProps<typeof Slider>;
type TTestimonialSlideProps = React.ComponentProps<typeof TestimonialSlide>;

interface ITestimonialsCarouselProps {
  items: (TTestimonialSlideProps & { id: string | number })[];
  sliderSettings?: Partial<TSliderProps>;
  isRebranding?: boolean;
  setRef?: (ref: Slider | null) => void;
}

const transitionTime = '600ms';

const getClasses = (isRebranding: boolean): SxClassProps => ({
  testimonialsCarousel: [
    (theme) => ({
      margin: '0 auto',
      position: 'relative',
      width: '95%',

      maxWidth: {
        xs: '500px',
        sm: '800px',
        md: '900px',
        lg: '1280px',
      },

      '& .slick-slider': {
        '& .slick-dots': {
          bottom: '-45px',
          '& li': {
            width: '12px',
            height: '12px',
            border: `2px solid ${theme.palette.care.grey[400]}`,
            borderRadius: '20px',
            marginRight: theme.spacing(3),

            '&.slick-active': {
              backgroundColor: theme.palette.care.grey[400],
            },

            '& button:before': {
              color: 'transparent',
            },
          },
        },
      },
    }),
    isRebranding && {
      width: {
        xs: '100%',
        lg: '1050px',
      },
      maxWidth: 'none',

      '& .slick-slider': {
        '& .slick-slide > div > .MuiBox-root': {
          opacity: {
            xs: 1,
            md: 0.5,
          },
          zIndex: 1,
          position: 'relative',
          transform: {
            xs: 'scale(1)',
            lg: 'scale(0.8)',
          },
          filter: {
            xs: 'blur(0)',
            lg: 'blur(2px)',
          },
          transition: `opacity ${transitionTime}, transform ${transitionTime}`,
        },

        '& .slick-slide.slick-active > div > .MuiBox-root': {
          opacity: {
            xs: 1,
            lg: 0.5,
          },
          zIndex: 1,
          position: 'relative',

          transform: {
            xs: 'scale(1)',
            lg: 'scale(0.8)',
          },
          transition: `opacity ${transitionTime}, transform ${transitionTime}`,
        },
        '& .slick-slide.slick-center > div > .MuiBox-root': {
          opacity: 1,
          zIndex: {
            xs: 1,
            lg: 3,
          },
          overflow: 'visible',
          transform: 'scale(1)',
          filter: 'blur(0)',

          transition: `opacity ${transitionTime}, transform ${transitionTime}`,
        },
        '& .slick-dots': {
          bottom: '-43px',
          width: {
            xs: '100%',
            lg: '637px',
          },
          '& li': {
            width: '8px',
            height: '8px',
            backgroundColor: '#203D5B',
            border: `2px solid #203D5B`,
            borderRadius: '20px',
            marginRight: 2,
            marginLeft: 0,
            transition: 'width 0.3s',

            '&.slick-active': {
              width: '24px',
              backgroundColor: '#203D5B',
            },

            '& button:before': {
              color: 'transparent',
            },
          },
        },
      },
    },
  ],
});

/** @see https://react-slick.neostack.com/docs/api */
const sliderDefaultSettings: TSliderProps = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  touchThreshold: 150,
  arrows: false,
};

function TestimonialsCarousel({
  items,
  sliderSettings,
  isRebranding,
  setRef,
}: ITestimonialsCarouselProps) {
  const sliderRef = useRef<Slider | null>();
  const { isMobile, isDesktopLG } = useResponsive();
  const classes = getClasses(Boolean(isRebranding));

  useEffect(() => {
    sliderRef.current?.slickGoTo(0);
  }, [isMobile]);

  useEffect(() => {
    if (isRebranding) {
      sliderRef.current?.slickGoTo(0);
    }
  }, [isDesktopLG]);

  return (
    <Box sx={classes.testimonialsCarousel}>
      <Slider
        ref={(slider) => {
          sliderRef.current = slider;
          if (slider && setRef) {
            setRef(slider);
          }
        }}
        {...sliderDefaultSettings}
        {...sliderSettings}>
        {items.map(({ id, ...props }) => (
          <TestimonialSlide key={id} {...props} isRebranding={isRebranding} />
        ))}
      </Slider>
    </Box>
  );
}

export default TestimonialsCarousel;
