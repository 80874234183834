import React from 'react';
import Image, { StaticImageData } from 'next/image';
import { Box, Typography } from '@mui/material';
import { Icon24InfoBgcFilled } from '@care/react-icons';
import { Divider, Rating } from '@care/react-component-lib';

import { SxClassProps } from '@/types';

const classes: SxClassProps = {
  slide: (theme) => ({
    height: '355px',
    backgroundColor: theme.palette.care?.white,
    borderRadius: '16px',
    border: `1px solid ${theme.palette.care?.grey[200]}`,
    padding: theme.spacing(3),
    margin: '0 12px',

    [theme.breakpoints.between('sm', 'lg')]: {
      padding: '20px 16px',
    },
    [theme.breakpoints.up('sm')]: {
      height: '313px',
      width: '246px',
      margin: '0 auto',
    },
    [theme.breakpoints.up('md')]: {
      width: '278px',
      height: '295px',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '20px',
      width: '390px',
      height: '315px',
    },
  }),
  providerSummary: (theme) => ({
    display: 'flex',
    columnGap: theme.spacing(3),

    [theme.breakpoints.between('sm', 'lg')]: {
      columnGap: '10px',
    },
  }),
  providerImageWrapper: (theme) => ({
    flexShrink: 0,
    width: '86px',
    height: '86px',
    overflow: 'hidden',
    borderRadius: '16px',

    '& img': {
      width: '100%',
      height: '100%',
    },

    [theme.breakpoints.between('sm', 'lg')]: {
      width: '60px',
      height: '60px',
    },
  }),
  providerName: (theme) => ({
    [theme.breakpoints.between('sm', 'lg')]: {
      ...theme.typography.h3,
    },
  }),
  providerLocation: {
    textWrap: 'nowrap',
  },
  providerRating: {
    marginTop: '4px',
    display: 'flex',
    '& .MuiRating-root': {
      gap: '3px',
    },
  },
  review: (theme) => ({
    marginBottom: theme.spacing(2),

    [theme.breakpoints.between('sm', 'lg')]: {
      ...theme.typography.body2,
      lineHeight: '22px',
    },
  }),

  // rebranding
  slideRebranding: {
    width: {
      xs: '327px',
      sm: '296px',
      lg: '409px',
    },
    height: {
      xs: '358px',
      sm: '380px',
      lg: '394px',
    },
    backgroundColor: 'transparent',
    boxSizing: 'content-box',
    border: `initial`,
    padding: '4px',
    margin: '0 4px',
  },
  imageSummaryContainer: {
    position: 'relative',
    overflow: 'hidden',
    borderRadius: '16px 16px 0 0 ',
    boxShadow: '0 0 8px 0 rgba(0, 0, 0, 0.15)',
  },
  imageContainer: {
    width: '100%',
    height: {
      xs: '202px',
      lg: '228px',
    },
    overflow: 'hidden',
  },
  providerSummaryRebranding: (theme) => ({
    backgroundColor: theme.palette.care?.white,
    borderRadius: '8px',

    padding: {
      xs: '8px 11px',
      lg: '8px 18px',
    },
    position: 'absolute',
    bottom: '16px',
    right: '16px',
  }),
  providerNameRebranding: {
    fontSize: {
      xs: '16px',
      lg: '21px',
    },
  },
  providerLocationRebranding: {
    fontSize: '14px',
    color: '#505D6D',
  },
  providerRatingRebranding: {
    marginTop: '0',
    display: 'flex',
    '& .MuiRating-root': {
      gap: '3px',
    },
    color: '#505D6D',
  },
  reviewWrapper: (theme) => ({
    backgroundColor: theme.palette.care?.white,
    padding: 2,
    overflow: 'hidden',
    borderRadius: '0 0 16px 16px',
    boxShadow: '0 0 8px 0 rgba(0, 0, 0, 0.15)',
  }),
  reviewRebranding: {
    fontSize: '16px',
    lineHeight: '22px',
    marginBottom: 2,
  },
};

// Types & Interfaces
interface ITestimonialSlideProps {
  image: StaticImageData;
  name: string;
  review: string;
  reviewer: string;
  rating: number;
  numberOfReviews: number;
  location: string;
  isRebranding?: boolean;
}

function TestimonialSlide({
  name,
  image,
  review,
  reviewer,
  rating,
  numberOfReviews,
  location,
  isRebranding,
}: ITestimonialSlideProps) {
  return (
    <Box sx={isRebranding ? classes.slideRebranding : classes.slide}>
      {isRebranding ? (
        <Box sx={classes.imageSummaryContainer}>
          <Box sx={classes.imageContainer}>
            <Image
              alt={name}
              src={image}
              layout="fill"
              objectFit="cover"
              objectPosition="50% 50%"
              unoptimized
            />
          </Box>
          <Box sx={classes.providerSummaryRebranding}>
            <Box display="flex" alignItems="center" gap={1}>
              <Typography variant="h2" sx={classes.providerNameRebranding}>
                {name}
              </Typography>
              <Icon24InfoBgcFilled size={24} />
            </Box>

            <Typography variant="body2" sx={classes.providerLocationRebranding}>
              {location}
            </Typography>

            <Box sx={classes.providerRatingRebranding}>
              <Rating readOnly size="medium" value={rating} />
              <Typography variant="info1">({numberOfReviews})</Typography>
            </Box>
          </Box>
        </Box>
      ) : (
        <>
          <Box sx={classes.providerSummary}>
            <Box sx={classes.providerImageWrapper}>
              <img src={image.src} alt="testimonial" />
            </Box>
            <Box>
              <Typography variant="h2" sx={classes.providerNameRebranding}>
                {name}
              </Typography>
              <Typography variant="body2" sx={classes.providerLocation}>
                {location}
              </Typography>

              <Box sx={classes.providerRating}>
                <Rating readOnly size="medium" value={rating} />
                <Typography variant="info1">({numberOfReviews})</Typography>
              </Box>
            </Box>
          </Box>
          <Divider orientation="horizontal" variant="hairline" />
        </>
      )}
      <Box sx={isRebranding ? classes.reviewWrapper : {}}>
        <Typography variant="body1" sx={isRebranding ? classes.reviewRebranding : classes.review}>
          &quot;{review}&quot;
        </Typography>
        <Typography variant="h5">{reviewer}</Typography>
      </Box>
    </Box>
  );
}

export default TestimonialSlide;
