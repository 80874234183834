import React from 'react';
import providerImg1 from '@/../public/value-drivers/dina.jpg';
import providerImg2 from '@/../public/value-drivers/lexi.jpg';
import providerImg3 from '@/../public/value-drivers/tayler.jpg';
import rebrandingImg1 from '@/../public/rebranding/DT_Review_1.jpg';
import rebrandingImg2 from '@/../public/rebranding/DT_Review_2.jpg';
import rebrandingImg3 from '@/../public/rebranding/TB_Review_3.jpg';
import TestimonialsCarousel from '@/components/shared/TestimonialsCarousel';

type TestimonialsCarouselProps = React.ComponentProps<typeof TestimonialsCarousel>;
export const TESTIMONIAL_ITEMS: TestimonialsCarouselProps['items'] = [
  {
    id: 1,
    image: providerImg1,
    name: 'Dina R.',
    review:
      'Dina was wonderful with my grandfather. She was patient, kind and caring. Very respectful and always on time, she was a gem!',
    reviewer: 'Lori J.',
    rating: 5,
    numberOfReviews: 8,
    location: 'Bethlehem, PA, 18018',
  },
  {
    id: 2,
    image: providerImg2,
    name: 'Lexi C.',
    review:
      'Lexi did great with my 6 year old who has never had a non–family babysitter. She was calm, patient, and my kiddo had a great time. Would definitely hire again!',
    reviewer: 'Margo E.',
    rating: 5,
    numberOfReviews: 12,
    location: 'Kent, WA 98031',
  },
  {
    id: 3,
    image: providerImg3,
    name: 'Taylen G.',
    review:
      'We love Taylen! She was so great with both of our boys. I would hire her 100 more times. She does really well and both of our kids love her!',
    reviewer: 'Hannah D.',
    rating: 5,
    numberOfReviews: 9,
    location: 'Atoka, TN 38004',
  },
];

export const REBRANDING_TESTIMONIAL_ITEMS: TestimonialsCarouselProps['items'] = [
  {
    id: 1,
    image: rebrandingImg1,
    name: 'Alice W.',
    review:
      'Alice treated my mother as if she were her own! She helped our family in ways we didn’t even know we needed. I would recommend her to anyone.',
    reviewer: 'Lisa B.',
    rating: 5,
    numberOfReviews: 8,
    location: 'Hodgenville, KY 42748',
  },
  {
    id: 2,
    image: rebrandingImg2,
    name: 'Lexi S.',
    review:
      'Lexi did great with my 6yo who has never had a non family babysitter. She was calm, patient, and my kiddo had a great time. Would definitely hire again!',
    reviewer: 'Margo B.',
    rating: 5,
    numberOfReviews: 12,
    location: 'Kent, WA 98031',
  },
  {
    id: 3,
    image: rebrandingImg3,
    name: 'Taylen C.',
    review:
      'We love Taylen! She was so great with both of our boys. I would hire her 100 more times. She does really well and both of our kids love her!',
    reviewer: 'Hannah T.',
    rating: 5,
    numberOfReviews: 9,
    location: 'Atoka, TN 38004',
  },
];
